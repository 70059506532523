@tailwind base;
@tailwind utilities;

body {
  @apply bg-lightGray font-roboto;
}

.react-tabs__tab--selected {
  @apply bg-gray/10 text-black font-bold;
}

.react-tabs__tab--selected svg g path {
  @apply fill-white;
}

.react-tabs__tab-panel--selected {
  @apply mt-10;
}

.rc-pagination-item {
  @apply transition-all text-white bg-extraLightBlue/50 rounded-md hover:bg-deepBlue;
}

.rc-pagination-item-active {
  @apply bg-mediumBlue text-white;
}

.rc-pagination-disabled {
  @apply opacity-50;
}

.react-datepicker__input-container > input {
  @apply w-full py-2 px-2.5 border border-mediumBlue rounded-xl placeholder:text-gray1;
}

.react-datepicker__input-container > .customer {
  @apply py-[11px];
}

.react-datepicker__day--selected {
  @apply bg-extraLightBlue;
}

.is-loading > * {
  height: 60px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.indicator {
  @apply absolute transition-all rounded-full border-4 bg-lightBlue border-[#EEEEEE] w-[72px] h-[72px] top-0 left-6;
}

.indicator::before {
  @apply absolute content-none lg:content-[''] left-[26%] w-[18px] h-5 -top-[20px] rotate-90 bg-transparent rounded-tr-[20px] shadow-sidebar;
}

.indicator::after {
  @apply absolute content-none lg:content-[''] left-[26%] w-[18px] h-5 -bottom-[20px] rotate-90 bg-transparent rounded-tl-[20px] shadow-negative-sidebar;
}

ul li:nth-child(1).sidebar-active ~ .indicator {
  transform: translateY(calc((60px - 64px) * 1));
}

ul li:nth-child(2).sidebar-active ~ .indicator {
  transform: translateY(calc((60px + 32px) * 1));
}

ul li:nth-child(3).sidebar-active ~ .indicator {
  transform: translateY(calc((60px + 34px) * 2));
}

ul li:nth-child(4).sidebar-active ~ .indicator {
  transform: translateY(calc((60px + 35px) * 3));
}

ul li:nth-child(5).sidebar-active ~ .indicator {
  transform: translateY(calc((60px + 35px) * 4));
}

.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

.remove-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

input[type='file'] {
  display: none;
}

.react-datepicker__calendar-icon {
  @apply right-0 top-0 bottom-0.5 !py-0 my-auto fill-deepBlue;
}

input:focus + label,
input:not(:placeholder-shown) + label,
textarea:focus + label,
textarea:not(:placeholder-shown) + label {
  top: -36px;
  left: 0;
  font-size: 14px;
}
